<!-- eslint-disable radix -->
<template>
  <b-overlay variant="white" :show="isLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
    <div>
      <h1 v-if="false">
        {{ `showInterviewSettings ${showInterviewSettings}` }} <br />
        {{ `finalizeForm ${finalizeForm}` }} <br />
        {{ `$route.name ${$route.name !== "applicant-tracking-system-edit"}` }}
        <br />
      </h1>

      <b-card
        v-if="
          showInterviewSettings &&
          !finalizeForm &&
          $route.name !== 'applicant-tracking-system-edit'
        "
        border-variant="dark"
        class="text-center"
        header="Interview Format"
      >
        <div class="d-flex flex-wrap mt-1">
          <div class="mx-2">
            <b-form-radio
              v-model="formData.interview_type"
              class="mx-2"
              value="physical"
              >In-Person Interview</b-form-radio
            >
          </div>

          <div class="mx-2">
            <b-form-radio
              v-model="formData.interview_type"
              class="mx-2"
              value="virtual"
              >Virtual Interview</b-form-radio
            >
          </div>

          <div class="mx-2">
            <b-form-radio
              v-model="formData.interview_type"
              class="mx-2"
              value="speed_interview"
              >Virtual Speed Interview</b-form-radio
            >
          </div>

          <div class="vx-col w-1/2">
            <b-form-radio
              v-model="formData.interview_type"
              class="mx-2"
              :value="null"
            >
              None
            </b-form-radio>

          </div>
        </div>

        <div v-if="formData.interview_type === 'speed_interview'" class="vx-row mb-1">
          <b-form-input v-model="formData.interview_duration" type="number" class=""
            placeholder="How many minutes should the interview last?" />
        </div>

        <div v-if="formData.interview_type === 'physical'" class="vx-row mt-2 mb-1">
          <b-form-input v-model="formData.interview_location" type="text" class="w-full"
            placeholder="What will be the location of the event?" />

          <!-- <gmaps-map>
                  <gmaps-marker :options="interview_location" />
                </gmaps-map> -->
        </div>

        <div v-if="formData.interview_type === 'physical'" class="vx-row mt-2 mb-1">
          <b-form-input v-model="formData.max_applications" type="number" class="w-full"
            placeholder="What will be the maximum number of participants for this event?" />

          <!-- <gmaps-map>
                  <gmaps-marker :options="interview_location" />
                </gmaps-map> -->
        </div>
      </b-card>

      <b-card v-else-if="showInterviewSettings && finalizeForm && $route.name !== 'applicant-tracking-system-edit'"
        class="text-center">
        <JobFormTable :module-name="moduleName" />
      </b-card>

      <div v-else>
        <b-tabs v-model="tabIndex" justified>
          <b-tab class="pt-1" title="Job Details">
            <!-- v-if="allowBulkUpload" -->
            <div v-if="false" class="d-flex flex-gap-xl align-items-center m-1">
              <div class="my-1 d-flex flex-gap-md align-items-center">

                <h6 class="m-0 p-0 font-weight-bolder">
                  Bulk Upload
                </h6>
                <b-form-checkbox v-model="bulk_upload.state" name="check-button" switch inline
                  class="custom-control-primary" />
              </div>
              <div v-if="false" class="d-flex fade-in">
                <div class="mr-1">
                  <b-button variant="warning" type="filled" icon-pack="feather" icon="icon-arrow-down-circle"
                    @click="downloadJobTemplate">Download Job Template</b-button>
                </div>
                <div class="ml-1 d-flex flex-row flex-gap-md align-items-center">
                  <input ref="uploadCSV" class="hidden" type="file" accept=".xls,.xlsx,.xlsm" @change="uploadBulkJobs">

                  <b-button id="#button-with-loading" class="w-full shadow-lg" variant="primary"
                    @click="$refs.uploadCSV.click()">Upload File</b-button>

                  <h3 class="m-0 p-0">
                    {{
                      $isNotEmpty(bulk_upload.file.name)
                        ? bulk_upload.file.name
                        : `Please upload a file`
                    }}
                  </h3>
                </div>
              </div>
            </div>

            <div v-if="!bulk_upload.state" class="ml-1 fade-in mt-2"><!-- || $route.name === 'jobs-data' -->
              <div v-if="
                (
                  !specified_event_id
                  && (
                    $route.name !== 'applicant-tracking-system-edit'
                    && $route.name !== 'applicant-tracking-system-edit-specific'
                    && $route.name !== 'jobs-data'
                  )
                )
              " class="mx-1">
                <h6 class="font-weight-bolder" :class="event_id && event_id.length > 0 ? '' : 'text-danger'">
                  Available Event Groups *
                </h6>
                <EventSearch id="event-search" v-model="formData.event_id" name="event-search" :multiple="false"
                  :disabled="specified_event_id" @event-selected="selectedEvent" />
              </div>

              <div>
                <div class="d-flex flex-wrap mt-1">

                  <b-col v-if="$store.state.auth.ActiveUser.account_type === this.$AccountTypes.EMPLOYER" cols="12"
                    md="12">
                    <h6 class="font-weight-bolder">
                      Company Name *
                    </h6>
                    <b-form-input v-model="$store.state.auth.ActiveUser.company_name" required class="w-full"
                      disabled />

                    <b-row class="mt-1 ">
                      <b-form-checkbox v-if="specialCompanyChecks('A')" v-model="published_fields.recruiter"
                        class="ml-1" disabled>Publish Company Name</b-form-checkbox>
                      <b-form-checkbox v-else v-model="published_fields.recruiter" class="ml-1">Publish Company
                        Name</b-form-checkbox>
                    </b-row>
                  </b-col>
                  <b-col class="mt-1 mt-md-0" cols="12" md="12">

                    <h6 v-if="
                      $store.state.auth.ActiveUser.company_name === 'PersolKelly' || $store.state.auth.ActiveUser.company_name === 'ADECCO PERSONNEL PTE LTD'
                    " class="font-weight-bolder">
                      Job Industry
                    </h6>
                    <h6 v-else-if="
                      $store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY
                    " class="font-weight-bolder" :class="formData.company_id ? '' : 'text-danger'">
                      Client Company Name *
                    </h6>

                    <v-select v-if="
                      $store.state.auth.ActiveUser.company_name === 'PersolKelly' || $store.state.auth.ActiveUser.company_name === 'ADECCO PERSONNEL PTE LTD'
                    " v-model="formData.industry" placeholder="Choose the company industry" multiple
                      :options="allIndustries" label="text" class="selectExample w-full" :reduce="value => value.value"
                      @search="handleInput" />

                    <CompanySearch v-else-if="
                      $store.state.auth.ActiveUser.account_type === $AccountTypes.AGENCY
                    " id="register-account-company" ref="refCompanyIdSelect" v-model="formData.company_id"
                      :allow-add-new-company="true" :validator="true" name="register-account-company" :multiple="false"
                      :placeholder="'Start typing to get company suggestions'" @company-selected="selectedCompanies" />

                    <div v-if="
                      $store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY
                    " class="mt-1">
                      <b-form-checkbox v-if="specialCompanyChecks('A')" v-model="published_fields.employer" class=""
                        disabled>
                        Publish Client Company Name</b-form-checkbox>
                      <b-form-checkbox v-else v-model="published_fields.employer" class="">Publish Client Company
                        Name</b-form-checkbox>
                    </div>
                  </b-col>
                </div>

                <div class="d-flex flex-wrap mt-1">
                  <b-col cols="12" md="6">

                    <h6 class="font-weight-bolder">
                      Job Owner Email *
                    </h6>
                    <b-form-input v-model="$store.state.auth.ActiveUser.email_address" required type="email"
                      class="w-full" disabled placeholder="Job owner email" />
                  </b-col>
                  <b-col v-if="
                    $store.state.auth.ActiveUser.account_type === $AccountTypes.AGENCY
                  " class="mt-1 mt-md-0" cols="12" md="6">
                    <h6 class="font-weight-bolder"
                      :class="$isNotEmpty(formData.hiring_managers) || formData.from_source === 'efc' ? '' : 'text-danger'">
                      Hiring Manager Email * {{ formData.company_id ? "" : "(Select the company name first)" }}
                    </h6>
                    <HiringManagerSearch id="hiring-manager-search" ref="refHRSelect" v-model="formData.hiring_managers"
                      :validator="true" name="hiring-manager-search" :clear-select="clearSelected" :multiple="false"
                      :disabled="formData.company_id ? false : true"
                      :company-record="{ company_id: formData.company_id, company_name: formData.company_name }"
                      :placeholder="'Hiring Manager Email Address'" @hiring-manager-selected="selectedHiringManagers" />
                  </b-col>
                </div>

                <div class="mx-1 mt-1">

                  <h6 class="font-weight-bolder" :class="formData.position ? '' : 'text-danger'">
                    Job Position * {{ formData.company_id ? "" : "(Select the company name first)" }}
                  </h6>
                  <JobPositionSearch v-if="operation === 'write'" id="jobPositionSelect" ref="refJobPositionSelect"
                    v-model="formData.position" :validator="true" name="register-account-company" :multiple="false"
                    :disabled="formData.company_id || $store.state.auth.ActiveUser.company_id ? false : true" :allow-add-new="true" :event-id="eventId"
                    :source-name-list="$route.query.source_name ? [$route.query.source_name] : null"
                    :company-id="formData.company_id" :placeholder="'Start typing to get job position suggestions'"
                    @selected="selectedJobPosition" :clear-select="clearSelected"/>
                  <b-form-input v-else v-model="formData.position" :state="formData.position ? true : false" required
                    class="w-full" />
                </div>
                <div v-if="
                  $store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY
                  && !$route.name.includes('applicant-tracking-system-edit')
                " class="mx-1">
                  <b-form-checkbox v-model="formData.featured" class="mt-1 mb-1">Feature this job in the event listings
                    page</b-form-checkbox>
                </div>

                <div class="mx-1 mt-1">
                  <h6 v-if="specialCompanyChecks('A')" class="font-weight-bolder">
                    Client Profile
                  </h6>
                  <b-form-textarea v-if="specialCompanyChecks('A')" v-model="formData.client_profile" rows="4"
                    class="w-full" />
                </div>

                <div class="mx-1 mt-1">
                  <h6 class="font-weight-bolder" :class="formData.vacancies > 0 ? '' : 'text-danger'">
                    Number of Vacancies *
                  </h6>
                  <b-form-input ref="inputVacancies" v-model="formData.vacancies"
                    :state="formData.vacancies > 0 ? true : false" required
                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                    class="w-full" placeholder="" @click="$refs.inputVacancies.select()" />

                  <div class="vx-col mt-1 ">
                    <b-form-checkbox v-model="published_fields.vacancies">Publish Number of Vacancies</b-form-checkbox>
                  </div>
                </div>

                <div class="d-flex flex-wrap mt-1">
                  <b-col cols="12" md="6">
                    <h6 class="font-weight-bolder" :class="$validateGeneralAcceptedString(formData.working_days).state
                      && (
                        $isNotEmpty(formData.working_days)
                        || ['mcf', 'mcf_jobs', 'efc'].includes(formData.from_source)
                        || ['mcf'].includes($route.query.source_name)
                      ) ? '' : 'text-danger'
                      ">
                      Working Days
                      {{
                        $isNotEmpty(formData.working_days) ||
                        ["mcf", "mcf_jobs", "efc"].includes(
                          formData.from_source
                        ) ||
                        ["mcf"].includes($route.query.source_name)
                          ? ""
                          : "*"
                      }}
                    </h6>

                    <b-form-input v-model="formData.working_days" :state="formData.working_days
                      && formData.working_days.length > 0
                      && $validateGeneralAcceptedString(formData.working_days).state
                      " :required="!['mcf'].includes($route.query.source_name)" class="w-full"
                      placeholder="For Example, Monday to Friday" val-icon-danger="icon-x" val-icon-pack="feather" />
                    <b-form-invalid-feedback>
                      {{
                        $validateGeneralAcceptedString(formData.working_days)
                          .message
                      }}
                    </b-form-invalid-feedback>
                  </b-col>

                  <b-col class="mt-1 mt-md-0" cols="12" md="6">
                    <h6 class="font-weight-bolder" :class="$validateGeneralAcceptedString(formData.working_time).state
                      && (
                        $isNotEmpty(formData.working_time)
                        || ['mcf', 'mcf_jobs', 'efc'].includes(formData.from_source)
                        || ['mcf'].includes($route.query.source_name)
                      ) ? '' : 'text-danger'
                      ">
                      Working Hours
                      {{
                        $isNotEmpty(formData.working_time) ||
                        ["mcf", "mcf_jobs", "efc"].includes(
                          formData.from_source
                        ) ||
                        ["mcf"].includes($route.query.source_name)
                          ? ""
                          : "*"
                      }}
                    </h6>
                    <b-form-input v-model="formData.working_time" :state="$isNotEmpty(formData.working_time)
                      && $validateGeneralAcceptedString(formData.working_time).state
                      " :required="!['mcf'].includes($route.query.source_name)" class="w-full"
                      placeholder="For Example, 9am to 6pm" val-icon-danger="icon-x" val-icon-pack="feather" />
                    <b-form-invalid-feedback>
                      {{
                        $validateGeneralAcceptedString(formData.working_time)
                          .message
                      }}
                    </b-form-invalid-feedback>
                  </b-col>
                </div>

                <div class="mx-1 mt-1">
                  <h6 class="font-weight-bolder" :class="job_type ? '' : 'text-danger'">
                    Job Type *
                  </h6>

                  <div class="row ">
                    <b-form-radio v-model="job_type" class="col ml-1" value="parttime">Part-time</b-form-radio>
                    <b-form-radio v-model="job_type" class="col ml-1" value="fulltime">Full-time</b-form-radio>

                  </div>
                </div>

                <div class="mx-1 mt-1">

                  <h6 class="font-weight-bolder" :class="checkEmptyEmploymentType ? '' : 'text-danger'">
                    Employment Type *
                  </h6>
                  <div class="d-flex justify-content-between flex-wrap">
                    <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                      <b-form-checkbox

                        v-model="employment_type.internship_traineeship">Internship/Traineeship</b-form-checkbox>
                    </div>

                    <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                      <b-form-checkbox v-model="employment_type.freelance">Freelance</b-form-checkbox>
                    </div>

                    <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                      <b-form-checkbox v-model="employment_type.permanent">Permanent</b-form-checkbox>
                    </div>

                    <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                      <b-form-checkbox v-model="employment_type.contract">Contract</b-form-checkbox>
                    </div>

                    <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                      <b-form-checkbox v-model="employment_type.flexiwork">Flexi-work</b-form-checkbox>
                    </div>

                    <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                      <b-form-checkbox v-model="employment_type.temporary">Temporary</b-form-checkbox>
                    </div>

                    <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                      <b-form-checkbox v-model="employment_type.prn">
                        PRN
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap">
                  <b-col class="mt-1" cols="12" md="3">

                    <h6 class="font-weight-bolder"
                      :class="formData.salary_currency || formData.from_source === 'efc' ? '' : 'text-danger'">
                      Currency *
                    </h6>
                    <v-select v-model="formData.salary_currency"
                      :class="formData.salary_currency || formData.from_source === 'efc' ? '' : 'danger-select'"
                      placeholder="Choose the salary currency" class="w-full" :options="all_salary_currencies"
                      label="value" :reduce="value => value.value" />
                  </b-col>
                  <b-col class="mt-1" cols="12" md="3">
                    <h6 class="font-weight-bolder"
                      :class="formData.min_salary || formData.from_source === 'efc' ? '' : 'text-danger'">

                      Minimum Salary *
                    </h6>
                    <b-form-input ref="inputMinSalary" v-model="formData.min_salary" required class="w-full"
                      type="number" placeholder=""
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"

                      :state="formData.min_salary > 0 || formData.from_source === 'efc' ? true : false"
                      @click="$refs.inputMinSalary.select()" />
                  </b-col>

                  <b-col class="mt-1" cols="12" md="3">
                    <h6 class="font-weight-bolder"
                      :class="formData.max_salary || formData.from_source === 'efc' ? '' : 'text-danger'">
                      Maximum Salary *
                    </h6>
                    <b-form-input ref="inputMaxSalary" v-model="formData.max_salary" required class="w-full"
                      type="number" placeholder=""
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"

                      :state="!showSalaryWarning() || formData.from_source === 'efc'"
                      @click="$refs.inputMaxSalary.select()" />

                    <b-form-invalid-feedback>
                      {{
                        parseInt(formData.min_salary) >
                        parseInt(formData.max_salary)
                          ? "The minimum salary cannot be greater than the maximum salary"
                          : "You need to add a valid maximum salary."
                      }}
                    </b-form-invalid-feedback>
                  </b-col>

                  <b-col class="mt-1" cols="12" md="3">

                    <h6 class="font-weight-bolder" :class="formData.salary_cycle ? '' : 'text-danger'">
                      Salary Payment Cycle *
                    </h6>
                    <v-select v-model="formData.salary_cycle" :class="formData.salary_cycle ? '' : 'danger-select'"
                      placeholder="Choose the salary cycle" class="w-full" :options="all_salary_cycles" label="value"
                      :reduce="value => value.value" />
                  </b-col>
                </div>

                <div class="mt-2 ml-1">

                  <b-form-checkbox v-model="published_fields.salary">Publish Salary</b-form-checkbox>
                </div>

                <div v-if="$route.name === 'jobs-data'" class="d-flex flex-wrap">
                  <b-col class="mt-1" cols="12" md="6">
                    <h6 class="font-weight-bolder">
                      Team
                    </h6>
                    <b-form-input v-model="formData.team" class="w-full" label="Team" placeholder="Team Name" />
                  </b-col>
                  <b-col class="mt-1" cols="12" md="6">
                    <h6 class="font-weight-bolder">
                      Union
                    </h6>
                    <b-form-input v-model="formData.union" class="w-full" label="Union" placeholder="Union" />
                  </b-col>
                </div>

                <div class="vx-row w-100">

                  <h6 class="font-weight-bolder mx-1 mt-1"
                    :class="formData.working_location_type || formData.from_source === 'efc' ? '' : 'text-danger'">
                    Mode of work *
                  </h6>
                  <div class="d-flex flex-wrap justify-content-between mx-1">
                    <b-form-radio v-model="formData.working_location_type" class="vx-col w-1/3"
                      value="remote_only">Remote Only</b-form-radio>
                    <b-form-radio v-model="formData.working_location_type" class="vx-col w-1/3" value="hybrid">Hybrid
                      (Remote and Office)</b-form-radio>
                    <b-form-radio v-model="formData.working_location_type" class="vx-col w-1/3" value="physical">Office
                      Only</b-form-radio>
                  </div>

                  <b-row v-if="
                    formData.working_location_type === 'physical' ||
                    formData.working_location_type === 'hybrid'
                  " class="w-100 mx-0">

                    <b-col class="mt-1" cols="12" md="6">
                      <h6 class="font-weight-bolder">
                        Working Location Address
                      </h6>

                      <b-form-input v-model="formData.working_location_address" required class="w-full"
                        :danger="formData.working_location_address > 1 ? false : true"
                        danger-text="You need to add a valid address." val-icon-danger="icon-x"
                        val-icon-pack="feather" />
                    </b-col>

                    <b-col class="mt-1" cols="12" md="6">
                      <h6 class="font-weight-bolder">
                        Working Location State
                      </h6>
                      <b-form-input v-model="formData.working_location_state" required class="w-full"
                        :danger="formData.working_location_state > 1 ? false : true"
                        danger-text="You need to add a valid state." val-icon-danger="icon-x" val-icon-pack="feather" />
                    </b-col>

                    <b-col class="mt-1" cols="12" md="6">
                      <h6 class="font-weight-bolder">
                        Working Location Country
                      </h6>

                      <v-select v-model="formData.working_location_country"
                        placeholder="Choose the country where the job is located in" autocomplete class="w-full"
                        :options="allCountries" label="value" :reduce="value => value.value" />
                    </b-col>

                    <b-col class="mt-1" cols="12" md="6">
                      <h6 class="font-weight-bolder">
                        Working Location Postal Code
                      </h6>

                      <b-form-input v-model="formData.working_location_postal_code" required class="w-full" :danger="formData.working_location_postal_code > 1 ? false : true
                        " danger-text="You need to add a valid postal code." val-icon-danger="icon-x"
                        val-icon-pack="feather" />
                    </b-col>
                  </b-row>
                </div>

                <div class="d-flex flex-wrap">
                  <b-col class="vx-col w-1/2 mt-1" cols="12" md="6">

                    <h6 class="font-weight-bolder">
                      Position Level
                    </h6>
                    <v-select v-model="formData.position_level" placeholder="Select the position level" class="w-full"
                      :options="all_position_levels" label="value" :reduce="value => value.value" />
                  </b-col>

                  <b-col class="mt-1" cols="12" md="6">
                    <h6 class="font-weight-bolder">
                      Minimum Qualification Level
                    </h6>

                    <v-select v-model="formData.min_qualification" placeholder="Choose the qualification level"
                      class="w-full" :options="all_qualification_levels" label="value" :reduce="value => value.value" />
                  </b-col>
                </div>

                <div class="d-flex flex-wrap">
                  <b-col class="mt-1" cols="12" md="6">
                    <h6 class="font-weight-bolder">
                      Minimum Years Of Experience
                    </h6>
                    <b-form-input ref="refsMinYoeInput" v-model="formData.min_yoe" required type="number"
                      placeholder="Minimum years of experience required(Only numbers allowed)" class="w-full"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      @click="$refs.refsMinYoeInput.select()" />
                  </b-col>

                  <b-col class="mt-1" cols="12" md="6">
                    <h6 class="font-weight-bolder">
                      Maximum Years Of Experience (If there is no maximum years
                      of experience, leave it as blank)
                    </h6>
                    <b-form-input ref="refsMaxYoeInput" v-model="formData.max_yoe" required type="number"
                      placeholder="Maximum years of experience required(Only numbers allowed)" class="w-full"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      @click="$refs.refsMaxYoeInput.select()" />
                  </b-col>
                </div>

                <div class="mx-1 mt-1">
                  <h6 v-if="specialCompanyChecks('A')" class="font-weight-bolder">
                    How long should the job last before expiry?
                  </h6>
                  <div v-if="specialCompanyChecks('A')" class="d-flex flex-wrap mt-1">
                    <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">

                      <b-form-radio v-model="job_expiry" class="w-full" value="7">1 Week</b-form-radio>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                      <b-form-radio v-model="job_expiry" class="w-full" value="14">2 Weeks</b-form-radio>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                      <b-form-radio v-model="job_expiry" class="w-full" value="30">1 Month</b-form-radio>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                      <b-form-radio v-model="job_expiry" class="w-full" value="60">2 Months</b-form-radio>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                      <b-form-radio v-model="job_expiry" class="w-full" value="90">3 Months</b-form-radio>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap w-100">
                  <b-col class="mt-1" cols="12" md="6">

                    <h6 class="font-weight-bolder"
                      :class="$isNotEmpty(formData.description) && $validateGeneralAcceptedString(formData.description).state ? '' : 'text-danger'">
                      Job Description *
                    </h6>
                    <b-form-textarea v-model="formData.description" rows="4" class="w-full"
                      :state="$isNotEmpty(formData.description) && $validateGeneralAcceptedString(formData.description).state"
                      :label="formData.description
                        ? ''
                        : 'The job description needs to have at least 10 characters.'
                        " />
                    <b-form-invalid-feedback>
                      {{
                        $validateGeneralAcceptedString(formData.description)
                          .message
                      }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col class="mt-1" cols="12" md="6">

                    <h6 class="font-weight-bolder" :class="$isNotEmpty(formData.requirements) || ['mcf', 'mcf_jobs', 'efc'].includes(formData.from_source) ? '' : 'text-danger'
                      ">
                      Job Requirements
                      {{
                        $isNotEmpty(formData.requirements) || ['mcf', 'mcf_jobs', 'efc'].includes(formData.from_source) ?
                          '*' : '*'
                      }}
                    </h6>
                    <b-form-textarea v-model="formData.requirements" rows="4" class="w-full"
                      :class="$isNotEmpty(formData.requirements) || ['mcf', 'mcf_jobs', 'efc'].includes(formData.from_source) ? '' : 'danger-select'"
                      :state="$isNotEmpty(formData.requirements) && $validateGeneralAcceptedString(formData.requirements).state"
                      :label="$isNotEmpty(formData.requirements)
                        ? ''
                        : 'The job requirements needs to have at least 10 characters.'
                        " />
                  </b-col>
                </div>

                <div class="mx-1 mt-1">

                  <h6 class="font-weight-bolder">
                    Job Benefits
                  </h6>
                  <b-form-textarea v-model="formData.benefits" rows="4"
                    :state="$validateGeneralAcceptedString(formData.benefits).state" class="w-full"
                    label="List all the job benefits below" />
                  <b-form-invalid-feedback>
                    {{
                      $validateGeneralAcceptedString(formData.benefits).message
                    }}
                  </b-form-invalid-feedback>
                </div>

                <div class="mx-1 mt-1">
                  <h6 class="font-weight-bolder">
                    Required Skillsets (Press Enter after typing each skillset)
                  </h6>

                  <b-form-tags ref="inputRequiredSkills" v-model="formData.required_skills" input-id="tags-basic"
                    class="w-full" placeholder="Click enter after adding each required skill" remove-on-delete
                    :tag-validator="tagValidatorRequiredSkills" />

                </div>

                <div class="mx-1 mt-1">
                  <h6 class="mt-1 font-weight-bolder">
                    Customised Screening Questions (Press Enter after typing
                    each skillset)
                  </h6>
                  <b-form-tags ref="inputQuestions" v-model="formData.questions" input-id="tags-basic" class="w-full"
                    placeholder="Click enter after adding each question" :tag-validator="tagValidatorQuestions" />
                </div>
                <div class="mx-1 mt-1">
                  <h6 class="mt-1 font-weight-bolder"
                    :class="$validateGeneralAcceptedString(formData.applicant_description).state ? '' : 'text-danger'">
                    Brief Description Of Type Of Candidates You Are Looking For
                  </h6>
                  <b-form-textarea v-model="formData.applicant_description" rows="4" class="w-full"
                    :state="$validateGeneralAcceptedString(formData.applicant_description).state" :label="formData.applicant_description
                      ? ''
                      : 'The brief description needs to have at least 10 characters.'
                      " />
                  <b-form-invalid-feedback>
                    {{
                      $validateGeneralAcceptedString(
                        formData.applicant_description
                      ).message
                    }}
                  </b-form-invalid-feedback>
                </div>
              </div>


              <b-alert class="h-auto my-4" :active="notify_employer" variant="warning" icon-pack="feather"
                icon="icon-info">
                <p class="font-bold">
                  Note
                </p>
                <span>{{ notify_text }} </span>
              </b-alert>
            </div>
          </b-tab>

          <b-tab v-if="!isCompanyLoaUploaded" title="Administration Details">
            <div v-if="!isCompanyLoaUploaded">
              <div class="d-flex flex-wrap mt-1 mb-2">
                <b-col cols="12" md="12">

                  <h6 class="font-weight-bolder" :class="$isNotEmpty(loa_file) ? '' : 'text-danger'">
                    Please upload company LOA. *
                  </h6>

                  <div class="d-flex flex-wrap flex-gap-md">
                    <file-pond ref="pond-company-loa" name="company-loa-upload" label-idle="Upload your LOA."
                      accepted-file-types="application/pdf, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"

                      :server="companyLoaServer" :files="companyLoaFiles" credits="false"
                      style="height: 65px;width: 250px;" @init="handleCompanyLoaFilePondInit" />

                    <view-document :file="loa_file" :file-url="loa_file_url" title="View Uploaded LOA." />
                  </div>
                </b-col>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <div class="vx-row w-100 my-3">


        <b-button v-if="showInterviewSettings == true" variant="primary" class="w-64" @click="stepBack">Back</b-button>

        <!-- <div
          v-if="
            event_id && !event_id.endsWith('_job') && !event_id.endsWith('_pending') &&
              $route.name !== 'applicant-tracking-system-edit'
              && $route.name !== 'applicant-tracking-system-edit-specific'
              || ['jobs-data'].includes($route.name)
          "
          class="mx-auto float-left"
        >
          <b-button
            variant="primary"
            class="mr-3 w-64"
            @click="showInterviewSettings = false"
          >Back</b-button>
        </div> -->

        <div v-if="
          $route.name !== 'applicant-tracking-system-edit'
          && $route.name !== 'applicant-tracking-system-edit-specific'
        " class="mx-auto float-right">

          <ButtonSpinner :is-loading="isLoading">
            <b-button v-if="operation === 'write'" variant="primary" class="w-64" @click="addJob">Save
              Changes</b-button>
            <b-button v-else variant="primary" class="w-64" @click="editJob">Save Changes</b-button>
          </ButtonSpinner>
        </div>
      </div>

      <b-modal v-model="modalBulkUploadFailed.state" centered hide-footer size="md" title="Error(s) Detected">
        <div class="d-flex flex-column justify-content-center align-items-start w-100">
          <div class="d-flex flex-column flex-gap-y-lg overflow-auto w-100" style="height: 70vh;">
            <div v-if="isLoading" class="w-100 d-flex flex-row flex-gap-md align-items-center justify-content-start">
              <b-spinner variant="primary" type="grow" class="" style="width: 2rem; height: 2rem;" />
              <h6 class="m-0 p-0">
                Loading
              </h6>
            </div>

            <CompanySearch id="register-account-company" ref="refCompanyIdSelect" v-model="formData.company_id"
              class="d-none" :allow-add-new-company="true" :validator="true" name="register-account-company"
              :multiple="false" :placeholder="'Start typing to get company suggestions'"
              @company-selected="selectedCompanies" />
            <HiringManagerSearch v-if="modalBulkUploadFailed.showHRSelect" id="hiring-manager-search" ref="refHRSelect"
              v-model="formData.hiring_managers" class="d-none" :validator="true" name="hiring-manager-search"
              :multiple="false"
              :company-record="{ company_id: formData.company_id, company_name: formData.company_name }"
              :placeholder="'Hiring Manager Email Address'" @hiring-manager-selected="selectedHiringManagers" />

            <div v-for="(errorList, rowNumber) in modalBulkUploadFailed.errorDetails" :key="errorList.key"
              class="modal-body__white-space">
              <h5 class="m-0 p-0 font-weight-bolder">
                Row Number : {{ rowNumber }}
              </h5>

              <div class="d-flex flex-column flex-gap-md p-1">
                <div v-for="error in errorList" :key="error.key"
                  class="d-flex flex-wrap flex-md-nowrap flex-row flex-gap-sm justify-content-between align-items-center border-primary p-1">
                  <span class="">
                    {{ error.message }}
                  </span>

                  <div class="b-button-size-x-xs">
                    <template v-if="error.action === 'create_client_company'">

                      <b-button class="b-button-size-xs" variant="primary" @click="createClientCompany(error.data)">
                        Take Action
                      </b-button>
                    </template>

                    <template v-else-if="error.action === 'create_hiring_manager'">
                      <b-button class="b-button-size-xs" variant="primary" @click="createHiringManager(error.data)">
                        Take Action
                      </b-button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="mt-3 d-flex flex-wrap flex-gap-md justify-content-end w-100">
            <b-button class="b-button-size-x-md" variant="outline-danger" @click="modalBulkUploadFailed.state = false">
              Cancel
            </b-button>

            <b-button class="b-button-size-x-md" variant="danger" @click="showBulk">
              Upload New File
            </b-button>
          </div>
        </div>
      </b-modal>


      <b-modal v-model="modalRecordAction.state" centered hide-footer size="lg" title="Error(s) Detected">
        <div class="d-flex flex-column justify-content-center align-items-start w-100">
          <div class="d-flex flex-column flex-gap-y-lg overflow-auto w-100">
            Please choose a Job Status before proceeding
            <div class="d-flex flex-row flex-gap-md">
              <div>
                <b-form-radio v-model="formData.publication_status" class="mx-2" value="draft">Draft</b-form-radio>
              </div>
              <div>
                <b-form-radio v-model="formData.publication_status" class="mx-2" value="live">Publish</b-form-radio>
              </div>
            </div>
          </div>


          <div class="mt-3 d-flex flex-wrap flex-gap-md justify-content-end w-100">
            <b-button class="flex-fill" variant="outline-danger" @click="modalRecordAction.state = false">
              Cancel
            </b-button>

            <ButtonSpinner :is-loading="isLoading">
              <b-button class="flex-fill" variant="danger" :disabled="$isEmpty(formData.publication_status)" @click="() => {
                formData.record_action = 'edit_all'
                addJob()
              }">
                Add & edit selected Job on all Event
              </b-button>
            </ButtonSpinner>

            <ButtonSpinner :is-loading="isLoading">

              <b-button
                class="flex-fill"
                variant="primary"
                :disabled="$isEmpty(formData.publication_status)"
                @click="
                  () => {
                    formData.record_action = 'edit_this'
                    // * part of showing add another job dialog
                    addJob()
                  }
                "
              >
                Add Job to Event
              </b-button>
            </ButtonSpinner>
          </div>
        </div>
      </b-modal>
    </div>
  </b-overlay>
</template>
<script>
// Import FilePond plugins
import { orderBy } from "lodash"
import {
  allIndustries,
  allCountries,
  highestQualifications,
} from "@/constants/datastore"
import vSelect from "vue-select"
import CompanySearch from "@/components/CompanySearch.vue"
import HiringManagerSearch from "@/components/HiringManagerSearch.vue"
import JobPositionSearch from "@/components/JobPositionSearch.vue"
import EventSearch from "@/components/jobs/EventSearch.vue"
import JobFormTable from "@/components/jobs/JobFormTable.vue"

import vueFilePond from "vue-filepond"
// Import FilePond styles
import "filepond/dist/filepond.min.css"

// Import FilePond plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"

import { bulkUploadFormMixin } from "./mixins/bulkUploadFormMixins"

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
  name: "JobForm",
  components: {
    FilePond,
    vSelect,
    CompanySearch,
    HiringManagerSearch,
    JobPositionSearch,
    EventSearch,
    JobFormTable,
  },

  mixins: [bulkUploadFormMixin],

  props: {
    wizardRef: Object,
    allowBulkUpload: { type: Boolean, default: false },
    eventId: { type: String, default: null },
    clearData: { type: Boolean, default: false },
    jobId: { type: String, default: null },
    sourceName: { type: String, default: null },
    showInterviewInput: { type: Boolean, default: false },

    editRecord: { type: Object, default: () => {} },
    eventName: String,
  },
  data() {
    return {
      clearSelected: false,
      isCompanyLoaUploaded: true,
      companyLoaServer: {},
      companyLoaFiles: null,
      loa_file: null,
      loa_file_url: null,

      formData: {
        position: "",
        client_profile: "",
        salary_currency: "",
        salary_cycle: "",
        min_salary: 0,
        max_salary: 0,
        working_days: "",
        working_time: "",
        working_location_type: "",
        working_location_address: "",
        working_location_state: "",
        working_location_country: "Singapore",
        working_location_postal_code: "",
        max_applications: null,
        interview_location: "",
        interview_type: "",
        interview_duration: null,
        position_level: "",
        min_qualification: null,
        min_yoe: null,
        max_yoe: null,
        description: "",
        requirements: "",
        benefits: "",
        questions: [],
        required_skills: null,
        industry: [],
        publication_status: "pending",
        published_fields: [],
        hiring_managers: null,
        vacancies: 0,
        featured: false,
        applicant_description: "",
        post_channel: [],
      },
      retrievedFormData: {},
      initialFormData: {},
      published_fields: {
        vacancies: true,
        salary: false,
        employer: false,
        recruiter: true,
      },

      bulk_form_upload: {
        state: false,
        file: {},
      },
      //! DEPRECATE: replacing file upload with forms
      bulk_upload: {
        state: false,
        file: {},
      },
      job_type: null,
      hiring_managers: null,
      employment_type: {
        freelance: false,
        internship_traineeship: false,
        flexiwork: false,
        permanent: false,
        contract: false,
        temporary: false,
        prn: false,
      },
      all_position_levels: [
        { text: "Senior Management", value: "Senior Management" },
        { text: "Middle Management", value: "Middle Management" },
        { text: "Manager", value: "Manager" },
        { text: "Professional", value: "Professional" },
        { text: "Senior Executive", value: "Senior Executive" },
        { text: "Executive", value: "Executive" },
        { text: "Junior Executive", value: "Junior Executive" },
        { text: "Non-Executive", value: "Non-Executive" },
        { text: "Fresh/Entry Level", value: "Fresh/Entry Level" },
      ],

      all_salary_cycles: [
        { text: "Per Annum", value: "Yearly" },
        { text: "Per Month", value: "Monthly" },
        { text: "Per Week", value: "Weekly" },
        { text: "Per Day", value: "Daily" },
        { text: "Per Hour", value: "Hourly" },
      ],
      salary_currency: "SGD",
      all_salary_currencies: [
        { text: "SGD", value: "SGD" },
        { text: "USD", value: "USD" },
        { text: "EUR", value: "EUR" },
        { text: "GBP", value: "GBP" },
      ],
      showInterviewSettings: false,

      moduleName: "EMS",
      finalizeForm: false,
      showBulkJobTable: false,

      allEvents: [],
      isLoading: false,
      notify_employer: false,
      notify_text: "",
      all_qualification_levels: null,
      required_skillset_options: null,
      operation: "write",
      allCountries: [],
      event_id: null,
      specified_event_id: false,

      modalBulkUploadFailed: {
        state: false,
        message: [],
        actions: [],
        data: {},
        errorDetails: [],
        showHRSelect: true,
      },
      modalRecordAction: {
        state: false,
        data: {},
      },
      tabIndex: 0,
    }
  },
  computed: {
    changedEventID() {
      return this.formData.event_id
    },
    checkEmptyEmploymentType() {
      return Object.entries(this.employment_type).filter(([key, value]) => {
        if (value === true) {
          return key[0]
        }
      })
    },
  },
  watch: {
    // clearData(val, oldVal) {
    //   if (val) {
    //     this.checkOldBulkUploads()
    //   }
    // },
    formData: {
      deep: true,
      handler(value) {
        this.$emit("form-data", this.formData)
      },
    },
    published_fields: {
      deep: true,
      handler(value) {
        const output = Object.keys(this.published_fields).filter((key) => {
          if (this.published_fields[key] === true) {
            return key
          }
        })
        this.$set(this.formData, "published_fields", output)
        this.$emit("form-data", this.formData)
      },
    },
    employment_type: {
      deep: true,
      handler(value) {
        const output = Object.keys(this.employment_type).filter((key) => {
          if (this.employment_type[key] === true) {
            return key
          }
        })
        this.$set(this.formData, "employment_type", output)
        this.$emit("form-data", this.formData)
      },
    },
    job_type(val, oldVal) {
      this.formData.job_type = val
    },
    showInterviewSettings(val, oldVal) {
      if (oldVal === true) {
        // repopulate Client Company Name
        const { company_id, company_name } = this.formData

        setTimeout(() => {
          this.repopulateInputWithFormData()
        }, 100)

        setTimeout(() => {
          this.$set(this.formData, "company_id", company_id)
          this.$set(this.formData, "company_name", company_name)
        }, 2020)
      }
    },
    showInterviewInput(val, oldVal) {
      this.showInterviewSettings = val
    },
  },
  created() {
    this.all_industries = orderBy(allIndustries, ["value"], ["asc"])
    this.all_qualification_levels = orderBy(
      highestQualifications,
      ["value"],
      ["asc"]
    )
    this.allCountries = allCountries

    if (this.jobId) {
      this.getJob()
    }

    if (this.$isNotEmpty(this.editRecord)) {
      this.populateEditData()
      this.operation = "edit"
    }

    const companyID = this.$store.state.auth.ActiveUser.company_id
    this.formData.company_id = companyID
  },
  mounted() {
    // * part of fixing add another job dialog bug
    // this.event_id = this.$route.params.event_id
    this.event_id = this.eventId
    // this.formData.company_id = this.$store.state.auth.ActiveUser.company_id
    if (this.event_id && this.event_id.includes("-N")) {
      this.event_id = this.event_id.replace("-N", "")
    }
    if (this.eventId) {
      if (this.event_id.endsWith("_job")) {
        this.eventId = this.eventId.replace("_job", "")
      }
      if (this.event_id.endsWith("_event")) {
        this.eventId = this.eventId.replace("_event", "")
        localStorage.setItem("event-id", this.event_id)
      }
      this.formData.event_id = this.eventId
      this.specified_event_id = true
    }

    setTimeout(() => {
      this.checkIsLoaUploaded()
    }, 100)

    if (this.formData.company_name) {
      this.repopulateInputWithFormData()
    }
    // this.checkOldBulkUploads()

    // this.initialFormData = this.formData

    // setTimeout(() => {
    //   this.populateTestData()
    // }, 100)
    // this.formData.company_id = this.$store.state.auth.ActiveUser.company_id

    // console.log(`form data company id: ${this.formData.company_id}`)
  },
  methods: {
    checkOldBulkUploads() {
      const bulkJobForms = this.loadBulkJobForms()

      console.dir(`bulk job forms: ${bulkJobForms}`, {
        depth: 1,
      })

      if (this.$route.name === "applicant-tracking-system-edit") {
        this.moduleName = "ATS"
      }
      let keys = []
      if (bulkJobForms[this.moduleName]) {
        keys = Object.keys(bulkJobForms[this.moduleName])
      }

      const lastKey = keys[keys.length - 1]
      if (keys.length > 0 && !this.clearData) {
        this.formData = bulkJobForms[this.moduleName][lastKey]

        this.operation = "update"
        setTimeout(() => {
          this.repopulateInputWithFormData()
        }, 50)
      } else {
        this.resetForm([])
      }
    },
    populateTestData() {
      this.formData.position = `${
        this.$route.name
      } Job Creator ${new Date().toISOString()}`
      this.formData.client_profile = "cleint profile"
      this.formData.salary_currency = "SGD"
      this.formData.salary_cycle = "monthly"
      this.formData.min_salary = 1111
      this.formData.max_salary = 2222
      this.formData.working_days = "working days"
      this.formData.working_time = "working time"
      this.formData.working_location_type = "remote"
      this.formData.working_location_address = "working location address"
      this.formData.working_location_state = "working location state"
      this.formData.working_location_country = "Singapore"
      this.formData.working_location_postal_code = 123412
      this.formData.max_applications = 3
      this.formData.interview_location = "test"
      this.formData.interview_type = "virtual"
      this.formData.interview_duration = 12
      this.formData.position_level = "Senior Management"
      this.formData.min_qualification = "Bachelor's Degree or equivalent"
      this.formData.min_yoe = 1
      this.formData.max_yoe = 3
      this.formData.description = "descriptions"
      this.formData.requirements = "requirements"
      this.formData.benefits = "benefit"
      this.formData.questions = ["Question 1", "Question 2"]
      this.formData.required_skills = ["HTML", "CSS", "JS"]
      this.formData.industry = []
      this.formData.publication_status = "pending"
      this.formData.published_fields = []
      this.formData.hiring_managers = null
      this.formData.vacancies = 3
      this.formData.featured = false
      this.formData.applicant_description = "applicant description"
      this.job_type = "parttime"
      this.employment_type = { permanent: true }
    },

    checkIsLoaUploaded() {
      if (
        this.$isEmpty(this.jobId) ||
        this.$route.name === "applicant-tracking-system-edit-specific"
      ) {
        let proceed = true

        let url = `/api/company-loa-uploaded/events/${this.event_id}`
        if (this.$isEmpty(this.event_id)) {
          proceed = false
        }
        if (
          [
            "applicant-tracking-system-edit",
            "applicant-tracking-system-edit-specific",
          ].includes(this.$route.name)
        ) {
          url = `/api/company-loa-uploaded/companies/${this.$store.state.auth.ActiveUser.company_id}`
          proceed = true
        }

        if (proceed) {
          this.$http.get(url).then((response) => {
            const { success, output } = response.data

            if (success) {
              this.$set(this, "isCompanyLoaUploaded", true)
            } else {
              this.$set(this, "isCompanyLoaUploaded", false)
            }
          })
        }
      }
    },
    handleCompanyLoaFilePondInit() {
      const { company_id } = this.$store.state.auth.ActiveUser
      this.$handleFilePondInit(
        this,
        company_id,
        "pond-company-loa",
        "loa-documents"
      )
    },

    populateEditData() {
      this.$http
        .get(`/api/bot-job/${this.editRecord.id}`)
        .then((response) => {
          const { output, success } = response.data
          this.$set(this, "formData", output)

          this.job_type = output.job_type

          Object.entries(this.employment_type).forEach(([field, value]) => {
            if (this.formData.employment_type.includes(field)) {
              this.employment_type[field] = true
            } else {
              this.employment_type[field] = false
            }
          })

          this.repopulateInputWithFormData()
        })
        .catch(() => { })
    },

    getJob(id = null, excludeUpdate = []) {

      this.isLoading = true
      let url = ""
      let jobID = this.$route.params.job_id
        ? this.$route.params.job_id
        : this.$route.params.event_id
      if (id) {
        jobID = id
      }
      if (
        [
          "applicant-tracking-system-edit",
          "applicant-tracking-system-edit-specific",
        ].includes(this.$route.name)
      ) {
        url = `/api/ats-job/${jobID}`
      } else {
        jobID = jobID ? jobID.replace("_job", "") : jobID
        url = `/api/job/${jobID}`
      }

      this.$http
        .get(url)
        .then((response) => {
          const { success, output } = response.data

          if (
            this.$isNotEmpty(this.$store.state.auth.ActiveUser.company_name)
          ) {
            excludeUpdate.push("company_id")
            this.$set(this, "formData", {
              ...this.formData,
              ...output,
              company_id: this.formData.company_id,
              company_name: this.formData.company_name,
              hiring_managers: this.formData.hiring_managers,
              hiring_manager_details: this.formData.hiring_manager_details,
            })
          } else {
            this.$set(this, "formData", output)
          }

          this.$set(this, "retrievedFormData", { ...output })
          this.formData.publication_status = null
          // * this is to allow company to be changed the second time
          // this.$refs.refCompanyIdSelect.isFirstLoad = true
          // this.repopulateInputWithFormData(null, excludeUpdate)
          this.hiring_managers = output.hiring_managers
          this.job_type = output.job_type
          this.loa_file = output.organiser_loa_file
          if (this.$isNotEmpty(this.loa_file)) {
            this.$set(this, "isCompanyLoaUploaded", true)
            this.$generateDownloadUrl(this.loa_file).then((resp) => {
              this.loa_file_url = resp
            })
          }
          if (this.$route.query.action !== "add_job") {
            this.operation = "edit"
          }
          this.isLoading = false
        })
        .catch((error) => {
          console.error(error)
          this.$handleErrorResponse(error)
          this.isLoading = false
        })
    },

    getMCFJob(id = null) {
      this.isLoading = true
      let url = ""
      let jobID = this.$route.params.job_id
        ? this.$route.params.job_id
        : this.$route.params.event_id

      if (id) {
        jobID = id
      }

      if (
        [
          "applicant-tracking-system-edit",
          "applicant-tracking-system-edit-specific",
        ].includes(this.$route.name)
      ) {
        // url = `/api/ats-job/${jobID}`
      } else {
        jobID = jobID.replace("_job", "")
        url = `/api/mcf-job-for-job-form/${jobID}`
      }

      this.$http
        .get(url)
        .then((response) => {
          const { success, output } = response.data

          this.$set(this, "formData", output)

          this.repopulateInputWithFormData()

          this.hiring_managers = output.hiring_managers
          this.job_type = output.job_type
          this.loa_file = output.organiser_loa_file
          if (this.$isNotEmpty(this.loa_file)) {
            this.$set(this, "isCompanyLoaUploaded", true)
            this.$generateDownloadUrl(this.loa_file).then((resp) => {
              this.loa_file_url = resp
            })
          }

          if (this.$route.query.action !== "add_job") {
            this.operation = "edit"
          }

          this.isLoading = false
        })
        .catch((error) => {
          this.$handleErrorResponse(error)
          this.isLoading = false
        })
    },

    repopulateInputWithFormData(populateType = null, excludeUpdate = []) {
      this.repopulateSelectedCompany(excludeUpdate).then((res) => {
        const { hiring_manager_details } = this.formData
        if (hiring_manager_details) {
          this.$refs.refHRSelect.getHiringManagers(
            hiring_manager_details.email_address,
            false
          )
          if (
            !excludeUpdate.includes("hiring_managers") &&
            hiring_manager_details.fullname &&
            hiring_manager_details.email_address
          ) {
            const hiring_manager = {
              fullname: hiring_manager_details.fullname,
              email_address: hiring_manager_details.email_address,
              alias_email_address: hiring_manager_details.alias_email_address,
              id: hiring_manager_details.id,
              hiring_manager: `${hiring_manager_details.fullname} - ${hiring_manager_details.email_address}`,
            }
            this.$refs.refHRSelect.selected_hiring_managers = hiring_manager
            this.$set(
              this.formData,
              "hiring_managers",
              hiring_manager_details.id
            )
          }
          if (this.$isEmpty(this.formData.published_fields)) {
            this.formData.published_fields = []
          }
          Object.entries(this.published_fields).forEach(([field, value]) => {
            if (this.formData.published_fields.includes(field)) {
              this.published_fields[field] = true
            } else {
              this.published_fields[field] = false
            }
          })
          if (this.$isEmpty(this.formData.employment_type)) {
            this.formData.employment_type = []
          }
          Object.entries(this.employment_type).forEach(([field, value]) => {
            if (this.formData.employment_type.includes(field)) {
              this.employment_type[field] = true
            } else {
              this.employment_type[field] = false
            }
          })
          this.job_type = this.formData.job_type
        }
      })
    },
    repopulateSelectedCompany(excludeUpdate = []) {
      return new Promise((res, rej) => {

        if (!excludeUpdate.includes('company_id')) {

          if (this.$refs.refCompanyIdSelect) {
            this.$refs.refCompanyIdSelect.getEmployers(this.formData.company_name, false)
          }

          const company = {
            id: this.formData.company_id,
            company_name: this.formData.company_name,
            company_uen_no: this.formData.company_uen_no,
          }

          if (this.$refs.refCompanyIdSelect) {
            this.$refs.refCompanyIdSelect.selected_companies = company
          }
        }
        res()
      })
    },

    selectedHiringManagers(hiringManager) {
      if (this.$isNotEmpty(hiringManager)) {
        this.$set(this.formData, "hiring_managers", hiringManager.id)
        this.$set(this.formData, "hiring_manager_details", hiringManager)
      }

      if (

        this.modalBulkUploadFailed.state === true
        && this.$route.name !== 'applicant-tracking-system-edit'
        && this.$route.name !== 'applicant-tracking-system-edit-specific'
      ) {
        this.addJob() // retry to update the bulk files
      }

      this.$emit("selected-hiring-managers", hiringManager)
    },

    resetForm(exclude = ["company_id", "hiring_managers", "position"]) {
      const prevFormData = { ...this.formData }
      this.operation = "write"
      this.formData = {
        ...this.initialFormData,
        // * Exclude these keys below
        position: exclude.includes("position") ? prevFormData.position : "",
        id: this.$route.query.action === "add_job" ? null : prevFormData.id,

        ...(exclude.includes("company_id")
          ? {
              company_name: prevFormData.company_name,
              company_id: prevFormData.company_id,
              company_uen_no: prevFormData.company_uen_no,
            }
          : {
              company_name: null,
              company_id: null,
              company_uen_no: null,
            }),
        ...(exclude.includes("hiring_managers")
          ? {
              hiring_managers: prevFormData.hiring_managers,
              hiring_manager_details: prevFormData.hiring_manager_details,
            }
          : { hiring_managers: null, hiring_manager_details: null }),
        ...(exclude.includes("publication_status")
          ? {
              publication_status: prevFormData.publication_status,
            }
          : {
              publication_status: null,
            }),

        ...(exclude.includes("interview_settings")
          ? {
              interview_type: prevFormData.interview_type,
              interview_duration: prevFormData.interview_duration,
              interview_location: prevFormData.interview_location,
              max_applications: prevFormData.max_applications,
            }
          : {
              interview_type: null,
              interview_duration: null,
              interview_location: null,
              max_applications: null,
            }),
      }
      this.clearSelected = false
      this.repopulateInputWithFormData()
    },

    selectedCompanies(company) {
      if (company.id) {
        if (
          this.$isNotEmpty(this.formData.company_id) &&
          company.id !== this.formData.company_id
        ) {
          // * reset the form data if a job is already selected and auto-populated
          this.$set(this.formData, "company_id", company.id)
          this.$set(this.formData, "company_name", company.company_name)
          this.$set(this.formData, "company_uen_no", company.company_uen_no)
          this.resetForm(["company_id"])
          this.$set(this.formData, "hiring_managers", null)
          this.$refs.refHRSelect.all_hiring_managers = []
          this.$refs.refHRSelect.selected_hiring_managers = null
        }

        this.$set(this.formData, "company_id", company.id)
        this.$set(this.formData, "company_name", company.company_name)
        this.$set(this.formData, "company_uen_no", company.company_uen_no)
      }

      if (

        this.modalBulkUploadFailed.state === true
        && this.$route.name !== 'applicant-tracking-system-edit'
        && this.$route.name !== 'applicant-tracking-system-edit-specific'
      ) {
        this.addJob() // retry to update the bulk files
      }

      this.$emit("selected-companies", company)
    },
    selectedEvent(event) {
      this.formData.event_id = event.id
      this.event_id = event.id
    },
    showSalaryWarning() {
      let result = false
      if (this.formData.max_salary === 0) {
        result = true
      }

      if (
        parseInt(this.formData.min_salary) > parseInt(this.formData.max_salary)
      ) {
        result = true
      }
      return result
    },
    checkFilledFields() {
      let proceed = true
      const message = ""

      this.formData.published_fields = Object.keys(
        this.published_fields
      ).filter((key) => {
        if (this.published_fields[key] === true) {
          return key
        }
      })

      // this.formData.hiring_managers = this.hiring_managers
      if (
        this.$store.state.auth.ActiveUser.account_type ===
        this.$AccountTypes.EMPLOYER
      ) {
        this.formData.company_id = this.$store.state.auth.ActiveUser.company_id
      }
      this.formData.job_type = this.job_type

      this.formData.employment_type = Object.keys(this.employment_type).filter(
        (key) => {
          if (this.employment_type[key] === true) {
            return key
          }
        }
      )
      const unfilledCols = [...this.$el.getElementsByClassName("text-danger")]
      const unfilledColNames = unfilledCols.map((item) =>
        item.innerText.toLowerCase()
      )

      if (unfilledCols.length > 0) {
        if (unfilledCols.length === 1) {
          if (unfilledColNames[0].includes("loa")) {
            this.tabIndex = 1
          }
        }
        proceed = false
        const element = unfilledCols[0]
        element.scrollIntoView()

        this.$toastDanger(
          "Missing Job Data",
          "Please fill in the required values for the highlighted fields."
        )
      }

      return proceed
    },
    addJob() {
      // * part of showing add another job dialog
      if (this.specified_event_id && this.$isEmpty(this.formData.id)) {
        this.formData.publication_status = "pending"
      }
      this.isLoading = true
      const result = this.checkFilledFields()
      if (result) {

        if (this.bulk_upload.state) {
          //! DEPRECATE after bulk upload with form is finished
          if (!this.file_upload) {
            this.$toastDanger(
              "Missing Job Data",
              "Please upload the excel file containing the jobs before proceeding."
            )
          } else if (!this.showInterviewSettings) {
            this.showInterviewSettings = true
            this.finalizeForm = false
          } else {
            this.confirmUpload()
          }
        } else if (!this.showInterviewSettings && !this.finalizeForm) {
          this.$bvModal
            .msgBoxConfirm("Do you want to add another job?", {
              title: "Add More",
              size: "sm",
              okVariant: "success",
              okTitle: "Next Step",
              cancelTitle: "Add Another Job",
              cancelVariant: "outline-success",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {

              if (!value) {
                this.finalizeForm = false
                this.showInterviewSettings = false
                this.serverAddJob(result, false)

                this.selectedJobPosition(
                  {
                    value: null,
                  },
                  false,
                )
                this.job_type = null
                this.employment_type = {
                  freelance: false,
                  internship_traineeship: false,
                  flexiwork: false,
                  permanent: false,
                  contract: false,
                  temporary: false,
                  prn: false,
                }
                this.isLoading = false
                this.clearSelected = true
              } else {
                this.isLoading = false
                this.serverAddJob(result, false)
                this.finalizeForm = false
                this.showInterviewSettings = true
              }
            })
        } else if (this.showInterviewSettings && !this.finalizeForm) {
          this.isLoading = false
          const bulkJobForms = this.loadBulkJobForms()
          if (
            ![
              "applicant-tracking-system-edit",
              "applicant-tracking-system-edit-specific",
            ].includes(this.$route.name)
          ) {
            Object.keys(bulkJobForms[this.moduleName]).forEach(
              (localStorageId) => {
                bulkJobForms[this.moduleName][localStorageId].interview_type =
                  this.formData.interview_type
                bulkJobForms[this.moduleName][
                  localStorageId
                ].interview_duration = this.formData.interview_duration
                bulkJobForms[this.moduleName][
                  localStorageId
                ].interview_location = this.formData.interview_location
                bulkJobForms[this.moduleName][localStorageId].max_applications =
                  this.formData.max_applications
              }
            )
          }
          this.updateBulkJobForms(bulkJobForms)

          this.finalizeForm = true
          this.showBulkJobTable = true
        } else {

          this.serverAddJob(result)
        }

        // this.resetForm()
      }
    },

    serverAddJob(result, submit = true) {
      if (result) {
        let url = "/api/job/event"
        if (this.$route.name === "jobs-data") {
          url = "/api/bot/job/event"
        }
        const eventIdHasPattern = /(_g|(_job)|(-N))$/ // skip if event_id includes either, Added $ anchor to match the end
        if (

          this.$route.params.event_id
          && (this.$route.params.event_id.endsWith('_event') || !eventIdHasPattern.test(this.$route.params.event_id))
        ) {
          this.formData.event_id = this.eventId
          // this.formData.expires_on = this.formData.from_source === 'mcf_jobs' ? `${this.formData.expires_on} 23:59:59` : this.formData.expires_on
          delete this.formData.meta
          this.formData.source_name = this.formData.from_source
        }

        if (!submit) {
          this.addBulkJobForm(this.moduleName)
          return
        }

        const successIndex = []
        const saveProcess = new Promise((res, rej) => {
          const bulkJobForms = this.loadBulkJobForms()
          Object.keys(bulkJobForms[this.moduleName]).forEach(
            (localStorageId, index) => {
              this.formData = bulkJobForms[this.moduleName][localStorageId]

              this.$http
                .post(`${url}`, this.formData)
                .then((response) => {
                  if (index >= this.$length(bulkJobForms) - 1) {
                    setTimeout(() => {
                      res()
                    }, 500)
                  }
                  const { success } = response.data
                  if (success) {
                    this.$toastSuccess(`Job Form: ${this.formData.position}`, response.data.message)
                    successIndex.push(localStorageId)
                  } else {
                    this.$toastDanger(`Job Form: ${this.formData.position}`, response.data.message)
                  }
                })
                .catch((error) => {
                  this.$handleErrorResponse(error)
                })
            }
          )
        })

        saveProcess.then((resp) => {
          function compareNumbersDescending(a, b) {
            return b - a
          }
          successIndex.sort(compareNumbersDescending)

          successIndex.forEach((index) => {
            this.deleteBulkJobForm(this.moduleName, index)
          })
        })

        saveProcess.then((resp) => {
          this.isLoading = false
          if (this.specified_event_id) {
            if (
              this.$route.query.action === "add_job" &&
              this.$route.query.step_number === 3
            ) {
              this.$router.push({
                name: "participating-jobs",
                params: { event_id: this.eventId },
              })
            } else {
              this.$emit("back-group-add-job", false)
            }
          } else if (this.$route.name === "jobs-data") {
            this.$emit("submitted", true)
          } else {
            this.$router.push({ name: "manage-events" })
            // this.$router.push("/events-manager/events")
          }
        })
      }
    },

    editJob() {

      const result = this.checkFilledFields()

      if (result) {
        if (this.bulk_upload.state) {
          if (!this.file_upload) {
            this.$toastDanger(
              "Missing Job Data",
              "Please upload the excel file containing the jobs before proceeding."
            )
          } else if (!this.showInterviewSettings) {
            this.showInterviewSettings = true
          } else {
            this.confirmUpload()
          }
        } else if (!this.showInterviewSettings) {
          this.showInterviewSettings = true
        } else {
          this.serverEditJob(result)
        }

        // this.resetForm()
      }
    },
    serverEditJob(result) {
      let url = "/api/job/event"
      if (this.$route.name === "jobs-data") {
        url = "/api/bot/job/event"
      }

      if (result) {
        this.isLoading = true
        this.$http
          .put(url, this.formData)
          .then((response) => {
            const { success, output } = response.data
            if (success) {
              this.$toastSuccess("Event Manager", response.data.message)

              if (this.$route.name === "jobs-data") {
                this.$emit("submitted", true)
              } else if (this.event_id.endsWith("_job")) {
                this.$router.push({
                  name: "participating-jobs",
                  params: { event_id: output.event_id },
                })
              } else if (this.event_id.endsWith("_pending")) {
                this.$router.push({
                  name: "jobs-pending-approval",
                  params: { event_id: output.event_id },
                })
              }
            } else {
              this.$toastDanger("Event Manager", response.data.message)
            }

            this.isLoading = false
          })
          .catch((error) => {
            this.isLoading = false
            this.$handleErrorResponse(error)
          })
      }
    },

    hasFormDataChanged(formData, retrievedFormData) {
      let hasChanged = false

      const columnsToCheck = [
        "company_id",
        "hiring_managers",
        "vacancies",
        "working_days",
        "working_time",
        "job_type",
        // 'employment_type',
        "salary_currency",
        "min_salary",
        "max_salary",
        "salary_cycle",
        "working_location_type",
        "working_location_state",
        "working_location_country",
        "working_location_postal_code",
        "working_location_address",
        "position_level",
        "min_qualification",
        "min_yoe",
        "max_yoe",
        "description",
        "requirements",
        "required_skills",
        "applicant_description",
        "interview_type",
        "interview_duration",
        "interview_location",
      ]

      if (formData.id) {
        const diff = []
        Object.keys(formData).forEach((key) => {
          if (
            columnsToCheck.includes(key)
            && formData[key] !== retrievedFormData[key]
          ) {
            diff.push(key)
          }
        })
        if (this.$length(diff) > 0) {
          // * CHANGED
          hasChanged = true
          // if (
          //   this.$isNotEmpty(this.formData.id)
          //     && this.$isEmpty(this.formData.record_action)
          //     && this.formData.from_source !== 'mcf_jobs'
          // ) {
          //   this.modalRecordAction.state = true
          // } else {
          //   this.serverAddJob(result)
          // }
        } else {
          // * UNCHANGED
          // if (
          //   this.$isNotEmpty(this.modalRecordAction.data.event_ids)
          //     && this.modalRecordAction.data.event_ids.includes(this.eventId)
          // ) {
          //   this.$toastWarning('No Data Edited', 'The selected job has already joined this event, and no data was edited')
          // } else {
          //   this.formData.record_action = 'update_event_id'
          //   this.serverAddJob(result)
          //   // this.modalRecordAction.state = true
          // }
        }
      } else {
        // * NEW JOB
        // this.serverAddJob(result)
      }

      return { hasChanged, formData }
    },

    editEvent() {
      const result = this.checkFilledFields()
      const joinEventID = this.$route.params.event_id

      if (result) {
        const all_data = this.extract_values()
        all_data.data_id =
          joinEventID && joinEventID.length > 2 ? joinEventID : this.item_id
        all_data.type = this.operation

        this.$http
          .put("/api/events", { data: all_data })
          .then((response) => {
            if (this.operation === "update") {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "Event Manager",
                    icon: "AlertCircleIcon",
                    text:
                      joinEventID && joinEventID.length > 2
                        ? "Your job has been updated successfully"
                        : "Your event has been updated successfully",
                    variant: "success",
                  },
                },
                { timeout: this.$longestTimeout }
              )
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "Employment Data Deletion",
                    icon: "AlertCircleIcon",
                    text: "Data deleted successfully!",
                    variant: "danger",
                  },
                },
                { timeout: this.$longestTimeout }
              )
            }
            if (this.new_event_category !== "group") {
              if (joinEventID && joinEventID.endsWith("_pending")) {
                window.location.href = `/events-manager/pending-jobs/${this.selected_event_group}`
              } else {
                this.$router.push("/events-manager/events")
              }
            } else {
              // this.external_platform_jobs = response.data.platform_jobs;
              // this.all_selected_events = response.data.all_selected_events;

              this.external_platform_jobs = response.data.all_selected_events

              this.all_selected_events = response.data.all_selected_events

              this.participating_events_data = response.data.platform_jobs

              if (this.all_selected_companies.length > 0) {
                // this.participating_events_data =
                //   this.external_platform_jobs.filter((job) =>
                //     this.all_selected_events.includes(job._id)
                //   );
              }

              // this.$refs.wizard.changeTab(0, 1);
              this.$refs.wizard.nextTab()
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "Event Manager",
                    icon: "AlertCircleIcon",
                    text: "You can select the jobs that will be added to this event.",
                    variant: "success",
                  },
                },
                { timeout: this.$longestTimeout }
              )
            }
          })
          .catch(error => { })
      }
    },

    cleanRedirectionLink() {
      if (this.formData.redirection_link) {
        this.formData.redirection_link = this.formData.redirection_link
          .toLowerCase()
          .replace("https://", "")
          .replace("https://", "")
        if (this.formData.redirection_link.length > 0) {
          this.formData.redirection_link = `https://${this.formData.redirection_link}`
        } else {
          this.formData.redirection_link = null
        }
      } else {
        this.formData.redirection_link = null
      }

      return this.formData.redirection_link
    },

    specialCompanyChecks(group_type) {
      if (group_type === "A") {
        const companies = [
          "DYNAMIC HUMAN CAPITAL PTE. LTD.",
          "PersolKelly",
          "FASTCO PTE. LTD.",
          "ELITEZ & ASSOCIATES PTE. LTD.",
          "ELITEZ PTE. LTD.",
          "ADECCO PERSONNEL PTE LTD",
        ]

        if (
          companies.includes(this.$store.state.auth.ActiveUser.company_name)
        ) {
          return true
        }
      }

      return false
    },

    // bulk upload job
    async downloadJobTemplate() {
      const template = "job_template.xlsx"

      const templateUrl = await this.$generateDownloadUrl(
        `/templates/${template}`
      )

      if (templateUrl) {
        fetch(templateUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const fileURL = window.URL.createObjectURL(blob)
            const fileLink = document.createElement("a")

            fileLink.href = fileURL
            fileLink.setAttribute("download", template)
            document.body.appendChild(fileLink)
            fileLink.click()
          })
          .catch(console.error)
      } else {
        this.$toastDanger("Download Operation", "File does not exist")
      }
    },
    uploadBulkJobs(input) {
      this.file_upload = input
      this.$set(this.bulk_upload, "file", input.target.files[0])
      this.$toastSuccess("File Attachment", "File attached successfully.")

      this.$emit("uploaded-bulk-jobs-file", input)
    },
    showBulk() {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: "File Upload",
            icon: "AlertCircleIcon",
            text: "Click `Upload File` to upload a new file.",
            variant: "warning",
          },
        },
        { timeout: this.$longestTimeout }
      )
      this.$emit("upload-file-section", true)
      this.file_upload = ""
      this.showInterviewSettings = false
      this.modalBulkUploadFailed.state = false
      this.bulk_upload.file = {}
    },
    confirmUpload() {
      this.isLoading = true

      const input = this.file_upload
      this.$toastWarning("File Upload Status", "File upload in progress.")

      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()

        reader.onload = e => { }
        const fd = new FormData()
        fd.append("file", input.target.files[0])
        const bulkInterviewFormat = {
          interview_type: this.formData.interview_type,
          interview_duration: this.formData.interview_duration,
          interview_location: this.formData.interview_location,
          max_applications: this.formData.max_applications,
        }

        // const outerThis = this
        // const fileName = `${uuidv4()}.xlsx`
        // singleUpload(fd, 'ats-bulk-upload', fileName, null, null, null, null, null, outerThis)

        this.$http
          .post(
            `/api/upload-file-bulk-jobs/${this.eventId}?params=${JSON.stringify(
              bulkInterviewFormat
            )}`,
            fd
          )
          .then((response) => {
            this.modalBulkUploadFailed.errorDetails = []
            if (this.$isNotEmpty(response.data.errors)) {
              this.$set(
                this.modalBulkUploadFailed,
                "errorDetails",
                response.data.errors
              )
              this.modalBulkUploadFailed.state = true
            } else {
              this.$toastSuccess("File Upload", response.data.message)
              this.$emit("back-group-add-job", false)

              // this.$refs.uploadCSV.value = "";
              this.file_upload = ""
            }
            this.isLoading = false
          })
          .catch((error) => {
            this.isLoading = false
          })
      }
    },
    cancelUpload() {
      this.file_upload = ""
      this.$refs.uploadCSV.value = ""
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: "File Upload Status",
            icon: "AlertCircleIcon",
            text: "File upload cancelled.",
            variant: "success",
          },
        },
        { timeout: this.$longestTimeout }
      )
    },

    createClientCompany(errorData) {
      setTimeout(() => {
        this.$refs.refCompanyIdSelect.companyData.uen_no =
          errorData.client_company_uen
        this.$refs.refCompanyIdSelect.popupAddNewCompany = true
      }, 5)
    },

    createHiringManager(errorData) {
      this.$set(this.modalBulkUploadFailed, "showHRSelect", false)
      this.$set(this.formData, "company_id", errorData.client_company_id)
      this.$set(this.formData, "company_name", errorData.client_company_name)
      this.$set(this.modalBulkUploadFailed, "showHRSelect", true)
      setTimeout(() => {
        this.$refs.refHRSelect.hiringManagerData.fullname = ''
        this.$refs.refHRSelect.hiringManagerData.email_address = errorData.hiring_manager_email
        this.$refs.refHRSelect.openCreateHRForm()
      }, 5)
    },

    selectedJobPosition(selectedOption, noResult = false) {
      console.dir(selectedOption, {
        depth: 1,
      })
      if (noResult) {
        this.formData.position = selectedOption.value || null
        // this.jobId = null
        this.formData.id = null
        this.modalRecordAction.data.event_ids = selectedOption.event_ids
      } else {

        // this.jobId = selectedOption.value
        this.formData.id = selectedOption.value
        this.formData.position = selectedOption.text
        this.modalRecordAction.data.event_ids = selectedOption.event_ids

        if (selectedOption.source_table === "mcf_jobs") {
          this.getMCFJob(selectedOption.value)
          this.formData.source_id = selectedOption.value
        } else {
          const excludeUpdate = []
          this.formData.source_db = selectedOption.source_db
          // * so that the client company name and hiring manager does not change
          // if (this.$isNotEmpty(this.formData.company_id)) {
          //   excludeUpdate.push('company_id')
          //   excludeUpdate.push('hiring_managers')
          // }
          let id_url = selectedOption.value
          if (selectedOption.source_db) {
            id_url = `${selectedOption.value}_${selectedOption.source_db}`
          }
          this.getJob(id_url, excludeUpdate)
        }
      }
    },

    tagValidatorRequiredSkills(tag) {
      const checkedTag = this.$validateGeneralAcceptedString(tag)
      return checkedTag.state
    },

    tagValidatorQuestions(tag) {
      const checkedTag = this.$validateGeneralAcceptedString(tag, ["?"])
      return checkedTag.state
    },

    stepBack() {
      this.showInterviewSettings = false
      this.finalizeForm = false

      const bulkJobForms = this.loadBulkJobForms()
      if (this.$route.name === "applicant-tracking-system-edit") {
        this.moduleName = "ATS"
      }
      const keys = Object.keys(bulkJobForms[this.moduleName])
      const lastKey = keys[keys.length - 1]
      this.formData = bulkJobForms[this.moduleName][lastKey]

      setTimeout(() => {
        this.repopulateInputWithFormData()
      }, 50)
    },
  },
}
</script>

<style lang="scss">
.modal-button-width {
  width: 16ch;
}
</style>
